<template>
    <v-container>
        <div>
            <div>
                <img alt="Profile pic" :src="$auth.user.picture">
                <h2>{{ $auth.user.name }}</h2>
                <p>{{ $auth.user.email }}</p>
            </div>

            <div>
                <pre>{{ JSON.stringify($auth.user, null, 2) }}</pre>
            </div>
        </div>
    </v-container>
</template>

<script>

export default {
    name: "Account",
    components: {},
    props: {},
    data: () => ({}),
    mounted() {
    },
    computed: {
    },
    methods: {
    },
}
</script>

<style scoped>

</style>