<template>
    <v-container class="pa-6">
        Please wait while we log you out...
    </v-container>
</template>

<script>

export default {
    name: "Login",
    components: {},
    props: {},
    data: () => ({}),
    mounted() {
        this.$auth.logout({
            returnTo: window.location.origin
        });
    },
    computed: {
    },
    methods: {
    },
}
</script>
