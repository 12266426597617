<template>
    <v-card v-if="item" :class="$vuetify.breakpoint.mdAndUp ? 'px-6 pb-2' : ''">
        <v-card-title>
            {{ item.name }} &nbsp; - &nbsp;
            <br
                v-if="!$vuetify.breakpoint.mdAndUp"
            >
            {{ headerText }}
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-card-title>
        <v-card-text>
            <v-row class="mt-1">
                <v-col cols="12"
                       v-if="activeAlerts.length > 0"
                >
                    <v-icon
                        color="#b71c1c"
                        style="padding-right: 20px;"
                    >
                        mdi-alert-decagram
                    </v-icon>
                    {{activeAlerts.length}} notifications are in ALERT state!
                </v-col>
                <v-col md="4" cols="12">
                    <v-card min-height="420px">
                        <v-card-title>Status</v-card-title>
                        <v-card-text>
                            <v-list>
                                <v-list-item-group
                                    v-model="itemFilter"
                                    multiple
                                >
                                    <v-list-item
                                        v-for="(item, idx) in item.states"
                                        :key="item.agent"
                                        dense
                                    >
                                        <v-list-item-icon>
                                            <v-icon v-if="item.state==='ONLINE'" color="#33ee33">mdi-check-circle
                                            </v-icon>
                                            <v-icon v-else-if="item.state==='OFFLINE'" color="#ee3333">mdi-alert
                                            </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ regionData(item.agent).country }} - {{ item.state }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle>Last change {{ formatDate(item.ts) }}</v-list-item-subtitle>
                                            <v-list-item-subtitle
                                                v-if="item.certData.validUntilTs !== null && item.certData.validUntilTs !== ''"
                                                :style="getDaysUntil(item.certData.validUntilTs) < 30 ? 'color: red;' : 'color: green'"
                                            >SSL Cert valid for {{ getDaysUntil(item.certData.validUntilTs) }} days
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-icon>
                                            <v-icon
                                                v-if="itemFilter.indexOf(idx) === -1"
                                                :color="regionData(item.agent).color"
                                            >mdi-checkbox-blank-outline</v-icon>
                                            <v-icon
                                                v-else
                                                :color="regionData(item.agent).color"
                                            >mdi-checkbox-outline</v-icon>
                                        </v-list-item-icon>

                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col md="8" cols="12">
                    <v-card height="100%">
                        <VueApexChart
                            :height="$vuetify.breakpoint.mdAndUp ? '100%' : '420px'"
                            type="area"
                            :series="filteredAgentHistory"
                            :options="apexOptions"
                        ></VueApexChart>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="5" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-card height="420px">
                        <WorldMapVue
                            v-if="$vuetify.breakpoint.mdAndUp"
                            style="height: 400px;"
                            :country-data="countryColors"
                            :show-overlay="hoverStateEntry !== null"
                            @mouseenter="onMouseEnterMapCountry"
                            @mouseleave="onMouseLeaveMapCountry"
                            @click="onClickMapCountry"
                        >
                            <template v-slot:overlay>
                                <v-list-item dense>
                                    <v-list-item-icon>
                                        <v-icon v-if="hoverStateEntry.state==='ONLINE'" color="#33ee33">mdi-check-circle
                                        </v-icon>
                                        <v-icon v-else-if="hoverStateEntry.state==='OFFLINE'" color="#ee3333">mdi-alert
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ regionData(hoverStateEntry.agent).country }} - {{ hoverStateEntry.state }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Since {{ formatDate(hoverStateEntry.ts) }}</v-list-item-subtitle>
                                        <v-list-item-subtitle
                                            v-if="hoverStateEntry.certData.validUntilTs !== null && hoverStateEntry.certData.validUntilTs !== ''"
                                            :style="getDaysUntil(hoverStateEntry.certData.validUntilTs) < 30 ? 'color: red;' : 'color: green'"
                                        >SSL Cert valid for {{ getDaysUntil(hoverStateEntry.certData.validUntilTs) }} days
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </WorldMapVue>
                    </v-card>
                </v-col>

                <v-col md="7" cols="12">
                    <v-card :style="$vuetify.breakpoint.mdAndUp ? 'height:100%;' : ''">
                        <v-card-title
                        >Service Interruptions
                        </v-card-title>
                        <v-card-text>
                            <v-data-table
                                dense
                                :headers="eventHeaders"
                                :items="currentInterruptionHistory"
                                :items-per-page="7"
                            >
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import VueApexChart from 'vue-apexcharts';
import WorldMapVue from 'world-map-vue';
import moment from 'moment';
import {mapGetters} from "vuex";
import Vue from "vue";

export default {
    name: "MonitorDetails",
    components: {VueApexChart, WorldMapVue},
    props: {
        itemId: {
            default: null
        }
    },
    data: () => ({
        item: null,
        currStatusData: null,
        currentHistoryConvertedAll: [{name: '', data: [], iso: []}],
        currTotalAvg: 0,
        countryColors: {},
        itemFilter: [],
        hoverStateEntry: null,
        eventHeaders: [
            {
                text: 'Region',
                value: 'agent'
            },
            {
                text: 'Start',
                value: 'startFormatted'
            },
            {
                text: 'Stop',
                value: 'stopFormatted'
            },
            {
                text: 'Duration',
                value: 'durationFormatted'
            }
        ],
    }),
    created() {
        if (this.itemId) {
            this.loadDetails(this.itemId);
        }
    },
    computed: {
        ...mapGetters({
            statuses: 'monitors/sorted',
            regionData: 'agents/getRegionData'
        }),
        activeAlerts() {
            if(this.currStatusData) {
                return this.currStatusData.alerts.filter(s => s.state === 'OFFLINE');
            }
            return [];
        },
        currentInterruptionHistory() {
            if(!this.currStatusData || !this.currStatusData.events) {
                return [];
            }
            const interruptHistory = [];

            this.currStatusData.events.forEach(event => {
                if(event.state === 'ONLINE') {
                    const duration = parseInt(event.ts) - parseInt(event.prevTs);
                    interruptHistory.push({
                        agent: this.regionData(event.agent).country,
                        startFormatted: moment.utc(event.prevTs).format('MMMM Do YYYY, h:mm:ss a'),
                        stopFormatted: moment.utc(event.ts).format('MMMM Do YYYY, h:mm:ss a'),
                        durationFormatted: moment.utc(duration).format('HH:mm:ss')
                    });
                }
            });
            return interruptHistory;
        },
        headerText() {
            if (this.itemFilter.length === 0) {
                return "Avg response time: " + this.currTotalAvg.toFixed(3);
            }

            let tot = 0;
            let cnt = 0;
            this.itemFilter.forEach(idx => {
                const item = this.item.states[idx];
                const entry = this.currentHistoryConvertedAll.find(h => h.agent === item.agent);
                if (entry) {
                    tot += entry.avg;
                    cnt++;
                } else {
                    console.error("Could not find entry for agent: " + item.agent);
                }
            });
            return "Avg response time from " + cnt + " selected " + (tot / cnt).toFixed(3);
        },
        filteredAgentHistory() {
            if (this.itemFilter.length === 0) {
                return this.currentHistoryConvertedAll;
            }

            const retval = [];
            this.itemFilter.forEach(idx => {
                const item = this.item.states[idx];
                console.log(this.currentHistoryConvertedAll)
                const entry = this.currentHistoryConvertedAll.find(h => h.agent === item.agent);
                if (entry) {
                    retval.push(entry);
                } else {
                    console.error("Could not find entry for agent: " + item.agent);
                }
            });
            return retval;
        },
        apexOptions() {
            return {
                dataLabels: {
                    enabled: false
                },
                theme: {
                    mode: this.$vuetify.theme.dark ? 'dark' : 'light'
                },
                xaxis: {
                    type: 'datetime',
                    labels: {
                        format: 'HH:mm',
                    },
                    tickAmount: 6,
                },
                yaxis: {
                    min: 0
                },
                tooltip: {
                    x: {
                        format: 'hh:mm:ss dd MMM yyyy'
                    }
                },
                fill: {
                    type: 'solid',
                        opacity: 0.8
                    // type: 'gradient',
                    // gradient: {
                    //     shadeIntensity: 1,
                    //     opacityFrom: 0.7,
                    //     opacityTo: 0.9,
                    //     stops: [0, 100]
                    // }
                },
            };
        }
    },
    methods: {
        getDaysUntil(ts) {
            return Math.floor((parseInt(ts) - (new Date()).getTime()) / (1000 * 60 * 60 * 24));
        },
        formatDate(ts) {
            return moment(new Date(ts)).format('MMMM Do YYYY, h:mm:ss a');
        },
        onMouseEnterMapCountry(iso) {
            if (!iso) {
                return;
            }
            const entry = this.currentHistoryConvertedAll.find(item => item.iso.indexOf(iso) >= 0);
            if(entry) {
                this.hoverStateEntry = this.item.states.find(s => s.agent === entry.agent);
            }
            // Update your data/property to be displayed on the overlay.
        },
        onMouseLeaveMapCountry() {
            this.hoverStateEntry = null;
        },
        onClickMapCountry(iso) {
            console.log('Click Country ' + iso);
            if (!iso) {
                return;
            }
            const entry = this.currentHistoryConvertedAll.find(item => item.iso.indexOf(iso) >= 0);
            if (entry !== undefined) {
                const idx = this.item.states.findIndex(e => e.agent === entry.agent);
                if(idx >= 0) {
                    const inFilter = this.itemFilter.indexOf(idx);
                    if(inFilter === -1) {
                        this.itemFilter.push(idx);
                    } else {
                        this.itemFilter.splice(inFilter, 1);
                    }
                }
            }
        },
        capitalize(data) {
            if (typeof data !== 'string' || data.length === 0) {
                return '';
            }
            return data[0].toUpperCase() + data.substr(1);
        },
        getStatusText(item) {
            if (!item) {
                return '';
            }
            let text = '';

            if (item.hasOfflineHistory && item.events && item.events.length > 0) {
                const ts = new Date(item.events[0].ts);
                text += "Since " + moment(ts).format('MMMM Do YYYY, h:mm:ss a') + ':';
            } else {
                text += "";
            }

            return text;
        },
        async loadDetails(id) {
            this.item = this.$store.getters['monitors/byId'](id);
            if (!this.item) {
                console.log("Could not load item for " + id);
                return;
            }

            if (!this.item) {
                this.currentHistoryConverted = [{name: '', data: []}];
                return;
            }
            this.$nextTick(async () => {
                if (!Vue.prototype.$auth.isAuthenticated) {
                    console.error("Not authenticated");
                    return;
                }
                const token = await this.$auth.getTokenSilently();
                const response = await fetch(process.env.VUE_APP_API_BASE + '/monitors/' + this.item.id, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                const data = await response.json();
                const onlineHistory = {};
                const offlineHistory = [];
                const avgByAgent = {};
                for (const agent in data.history) {
                    let cnt = 0;
                    let sum = 0.0;
                    data.history[agent].forEach(item => {
                        // eslint-disable-next-line no-prototype-builtins
                        if (!onlineHistory.hasOwnProperty(agent)) {
                            onlineHistory[agent] = [];
                        }
                        onlineHistory[agent].push({
                            x: parseInt(item.ts),
                            y: item.state === 'ONLINE' ? parseFloat(item.duration).toFixed(3) : 0
                        });
                        if (item.state !== 'ONLINE') {
                            offlineHistory.push({
                                x: parseInt(item.ts),
                                y: 0,
                                agent: item.agent
                            });
                        } else {
                            cnt++;
                            sum += parseFloat(item.duration);
                        }
                    });
                    avgByAgent[agent] = (sum / cnt)
                }
                let totCnt = 0, totSum = 0.0;
                for (const agent in avgByAgent) {
                    totCnt++;
                    totSum += avgByAgent[agent];
                }
                this.currTotalAvg = (totSum / totCnt);

                this.currStatusData = data;
                this.currStatusData.events.forEach(item => {
                    item.date = moment(new Date(item.ts)).format('MMMM Do YYYY, h:mm:ss a');
                });
                this.currentHistoryConvertedAll = [];
                this.countryColors = {};
                for (const agent in onlineHistory) {
                    const entry = {
                        iso: null,
                        agent: agent,
                        name: agent,
                        data: onlineHistory[agent],
                        type: 'line',
                        avg: avgByAgent[agent],
                        show: false
                    };
                    if (this.regionData(agent).name !== 'unknown') {
                        const data = this.regionData(agent);
                        data.iso.forEach(iso => {
                            this.countryColors[iso] = data.color;
                        });
                        entry['color'] = data.color;
                        entry['name'] = data.country;
                        entry['iso'] = data.iso;
                    }
                    //entry.name += "(avg " + avgByAgent[agent] + "s)";
                    this.currentHistoryConvertedAll.push(entry);
                }
                this.currentHistoryConverted = this.currentHistoryConvertedAll;
                if (offlineHistory.length > 0) {
                    this.currentHistoryConverted.push({
                        name: 'Offline',
                        data: offlineHistory,
                        type: 'scatter',
                        color: '#b71c1c',
                        iso: [],
                        markers: {
                            size: 6
                        }
                    });
                }
            });
        }
    },
    watch: {
        itemId(itemId) {
            if (itemId) {
                this.itemFilter = [];
                this.currStatusData = null;
                this.loadDetails(itemId);
            } else {
                this.item = null;
                this.currStatusData = null;
                this.currentHistoryConvertedAll = [{name: '', data: []}];
                this.currentHistoryConverted = [{name: '', data: []}];
                this.currTotalAvg = 0;
            }
        },
        async statuses() {
            if (this.itemId) {
                await this.loadDetails(this.itemId);
            }
        }
    },
}
</script>

<style scoped>
</style>