import Vue from 'vue'
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import App from './App.vue'
import { domain, clientId, audience } from "../auth_config.json";
import { Auth0Plugin } from "./auth";

Vue.config.productionTip = false
Vue.prototype.$clone = (item) => JSON.parse(JSON.stringify(item));

Array.update = (list, item, idField) => {
    const index = list.findIndex(obj => obj[idField] === item[idField]);

    if (index > -1) {
        Vue.set(list, index, item);
        return list[index];
    } else {
        list.push(item);
        return null;
    }
};

Vue.use(Auth0Plugin, {
    domain,
    clientId,
    audience,   // NEW - configure the plugin with the audience value
    onRedirectCallback: appState => {
        router.push(
            appState && appState.targetUrl
                ? appState.targetUrl
                : window.location.pathname
        );
    }
});


new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
