<template>
    <v-container>
        <v-card elevation="0" class="pa-1">
            <v-card-text>
                <v-btn
                    style="margin-right: 30px"
                    @click="openCreate()"
                    :disabled="activeServices.length >= 10"
                >
                    <v-icon>mdi-plus</v-icon>
                    Add service
                </v-btn>
                You have {{ activeServices.length }} active services(your limit is 10)
            </v-card-text>
        </v-card>
        <v-expansion-panels
            focusable
            class="py-1"
        >
            <v-expansion-panel
                v-for="(item) in services"
                :key="item.id"
            >
                <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                        <v-row no-gutters>
                            <span :style="'margin: auto; font-weight: bold;' + (item.active ? '' : 'color: gray;')">
                                {{ item.name + (item.active ? '' : ' (disabled)') }}
                            </span>

                            <span
                                style="margin-left: 40px; margin-right: 20px;"
                                v-if="item.tags.length > 0"
                            >
                                <v-chip
                                    v-for="(tag) in item.tags"
                                    :key="tag"
                                >{{ tag }}
                                </v-chip>
                            </span>
                            <v-spacer style="flex:1;"></v-spacer>
                            <span
                                style="margin-right: 20px;"
                            >
                                <v-chip
                                    v-for="(alert) in serviceAlertsByServiceId(item.id)"
                                    :key="alert.id"
                                ><v-icon>mdi-alert-decagram-outline</v-icon> &nbsp; {{ getAlertName(alert) }}
                                </v-chip>
                            </span>
                            <v-icon
                                v-if="item.checkCert"
                                style="margin-right: 30px;"
                            >mdi-certificate
                            </v-icon>
                            <span
                                v-else
                                style="width: 24px; margin-right: 30px;"
                            ></span>
                            <span v-if="open" style="float: right;">
                            </span>
                        </v-row>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="8">
                            <v-card class="mt-3" elevation="0">
                                <v-card-title>General</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col>
                                            <span class="font-weight-bold">Interval:</span> {{ item.interval }}<br>
                                            <span class="font-weight-bold">URL:</span> {{ item.url }}<br>
                                            <span class="font-weight-bold">User-Agent:</span> {{ uaByKey(item.userAgent) }}<br>
                                            <span class="font-weight-bold">SSL cert check:</span>
                                            {{ item.checkCert ? 'Yes' : 'No' }}<br>
                                        </v-col>
                                        <v-col>
                                            <div>
                                                Alerts:
                                                <v-chip
                                                    v-for="(alert, idx) in serviceAlertsByServiceId(item.id)"
                                                    :key="idx"
                                                >
                                                    <v-icon>mdi-alert-decagram-outline</v-icon> &nbsp;
                                                    {{ getAlertName(alert) }}
                                                </v-chip>
                                            </div>
                                            <div v-if="item.tags.length > 0">
                                                Tags:
                                                <v-chip
                                                    v-for="(tag) in item.tags"
                                                    :key="tag"
                                                >{{ tag }}
                                                </v-chip>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                                <v-card-title
                                    v-if="typeof item.headers === 'object' && Object.keys(item.headers).length > 0"
                                >Headers
                                </v-card-title>
                                <v-card-text
                                    v-if="typeof item.headers === 'object' && Object.keys(item.headers).length > 0"
                                >
                                    <v-row dense>
                                        <v-col cols="6">
                                            <span class="font-weight-bold">Key</span>
                                        </v-col>
                                        <v-col cols="6">
                                            <span class="font-weight-bold">Value</span>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-for="(header) in item.headers"
                                        :key="header.key"
                                        dense
                                    >
                                        <v-col cols="6">
                                            {{ header.key }}
                                        </v-col>
                                        <v-col cols="6">
                                            {{ header.value }}
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>

                        </v-col>
                        <v-col>
                            <v-card height="100%" elevation="0" class="d-flex flex-column">
                                <v-spacer></v-spacer>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="mr-4" @click.stop="editService(item)">
                                        <v-icon>mdi-pencil</v-icon>
                                        Edit
                                    </v-btn>
                                    <v-btn class="mr-4" @click.stop="deleteService(item)" color="error">
                                        <v-icon>mdi-delete</v-icon>
                                        Delete
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-dialog width="80%" v-model="editor.show">
            <v-card class="pa-8">
                <v-card-title>{{ editor.item.id ? 'Edit Service' : 'New Service' }}</v-card-title>
                <v-tabs
                    color="deep-purple accent-4"
                    right
                >
                    <v-tab>General</v-tab>
                    <v-tab>Headers</v-tab>
                    <v-tab v-if="editor.item.id">Alerts</v-tab>
                    <v-tab-item key="1">
                        <v-container fluid>
                            <v-row class="px-3">
                                <v-text-field v-model="editor.item.name" style="margin-right: 20px;"
                                              label="Name"></v-text-field>
                                <v-checkbox
                                    label="Active"
                                    v-model="editor.item.active"
                                ></v-checkbox>
                            </v-row>
                            <v-text-field :rules="[rules.url]" v-model="editor.item.url" label="URL"></v-text-field>
                            <v-row>
                                <v-col>
                                    <v-text-field v-model="editor.item.interval" type="number"
                                                  label="Interval"></v-text-field>
                                </v-col>
                                <v-col>
                                    <v-select
                                        v-model="editor.item.userAgent"
                                        :return-object="false"
                                        label="User Agent"
                                        :items="userAgents"
                                        item-text="label"
                                        item-value="key"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-checkbox
                                label="Check SSL certificate expiration"
                                v-model="editor.item.checkCert"

                            ></v-checkbox>

                            <v-row>
                                <v-col>
                                    Tags: &nbsp;
                                    <v-chip
                                        v-for="(tag) in editor.item.tags"
                                        :key="tag"
                                        close
                                        @click:close="removeTag(tag, editor.item)"
                                    >
                                        <strong>{{ tag }}</strong>
                                    </v-chip>
                                </v-col>
                                <v-col>
                                    <v-row>
                                        <v-text-field
                                            v-model="tagToAdd"
                                            ref="tagfield"
                                            label="New tag"
                                            @keydown.enter="addTag()"
                                        ></v-text-field>
                                        <v-btn :disabled="!tagToAdd || editor.item.tags.indexOf(tagToAdd) >= 0"
                                               @click="editor.item.tags.push(tagToAdd); tagToAdd = ''">Add
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="2">
                        <v-container class="py-6" fluid style="min-height: 200px;">
                            <v-row
                                v-for="(header, idx) in editor.item.headers"
                                :key="idx"
                            >
                                <v-col cols="5.5">
                                    <v-text-field
                                        label="Key"
                                        v-model="header.key"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="5.5">
                                    <v-text-field
                                        label="Value"
                                        v-model="header.value"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="1">
                                    <v-btn @click="editor.item.headers.splice(idx, 1)">Remove</v-btn>
                                </v-col>
                            </v-row>
                            <v-btn @click="editor.item.headers.push({ key:'', value: ''})">Add header</v-btn>
                        </v-container>
                    </v-tab-item>
                    <v-tab-item key="3" class="py-6" fluid style="min-height: 200px;">
                        <v-container class="py-6" fluid style="min-height: 200px;">
                            <v-card
                                elevation="0"
                                v-for="(alert, idx) in editor.alerts"
                                :key="idx"
                            >
                                <v-row>
                                    <v-col>
                                        <v-select
                                            :items="alerts"
                                            item-value="id"
                                            item-text="name"
                                            v-model="alert.alertId"
                                        ></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Number of agents down to trig DOWN"
                                            v-model="alert.downLimit"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            label="Number of agents down to trig UP"
                                            v-model="alert.upLimit"
                                        ></v-text-field>
                                    </v-col>
                                    <v-btn @click="removeServiceAlert(alert)">Remove</v-btn>
                                </v-row>
                            </v-card>
                            <v-btn
                                @click="editor.alerts.push({ alertId: null, serviceId: editor.item.id, upLimit: 0, downLimit: 3})">
                                Add alert
                            </v-btn>
                        </v-container>
                    </v-tab-item>
                </v-tabs>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="saveService">{{ editor.item.id ? 'Save' : 'Create' }}</v-btn>
                    <v-btn color="error" @click="editor.show=false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog
            v-model="deleteDialog.show"
            width="500"
        >
            <v-card>
                <v-card-title class="text-h5 lighten-2">
                    Delete {{ deleteDialog.item ? deleteDialog.item.name : '' }}
                </v-card-title>

                <v-card-text>
                    Deleting a service will delete all configuration data and all historical data.<br>
                    This action cannot be undone!
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteDialog = { show: false, item: null }"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="error"
                        @click="confirmDeleteService()"
                    >
                        Delete Service
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import moment from 'moment';
import {mapGetters, mapState} from "vuex";

export default {
    name: "Services",
    components: {},
    props: {},
    data: () => ({
        alertsToDelete: [],
        tagToAdd: null,
        editor: {
            show: false,
            item: {
                name: '',
                url: '',
                interval: 120,
                headers: [],
                tags: []
            },
            alerts: []
        },
        deleteDialog: {
            show: false,
            item: null
        },
        rules: {
            url: str => {
                let url;

                try {
                    url = new URL(str);
                } catch (_) {
                    return false;
                }
                return url.protocol === "http:" || url.protocol === "https:";
            },
        }
    }),
    mounted() {
        this.$store.dispatch('services/fetch');
        this.$store.dispatch('alerts/fetch');
        this.$store.dispatch('servicealerts/fetch');
    },
    computed: {
        ...mapGetters({
            services: 'services/sorted',
            activeServices: 'services/active',
            userAgents: 'userAgents/getAll',
            uaByKey: 'userAgents/labelByKey',
            serviceAlertsByServiceId: 'servicealerts/byServiceId'
        }),
        ...mapState({
            alerts: state => state.alerts.items
        })
    },
    methods: {
        removeServiceAlert(alert) {
            this.alertsToDelete.push(alert);
            for (let i = 0; i < this.editor.alerts.length; i++) {
                if (this.editor.alerts[i] === alert) {
                    this.editor.alerts.splice(i, 1);
                    return;
                }
            }
        },
        getAlertName(alert) {
            const entry = this.alerts.find(a => a.id === alert.alertId);
            return entry ? entry.name : 'Unknown Alert';
        },
        openCreate() {
            this.tagToAdd = '';
            this.editor = {
                show: true,
                item: {
                    id: null,
                    name: '',
                    url: '',
                    interval: 120,
                    userAgent: 'default',
                    active: true,
                    headers: [],
                    tags: []
                },
                alerts: []
            };
        },
        capitalize(data) {
            if (typeof data !== 'string' || data.length === 0) {
                return '';
            }
            return data[0].toUpperCase() + data.substr(1);
        },
        getIcon(item) {
            switch (item.state) {
                case 'ONLINE':
                    return 'mdi-check-circle';
                case 'OFFLINE':
                    return 'mdi-alert';
            }
            return 'mdi-cloud-download';
        },
        getIconColor(item) {
            switch (item.state) {
                case 'OFFLINE':
                    return '#b71c1c';
                case 'UNKNOWN':
                    return '#777';
            }
            if (item.hasOfflineHistory === true) {
                return '#ccaa00';
            }
            return '#6f9a3b';
        },
        getStatusText(item) {
            if (!item) {
                return '';
            }
            let text = '';

            if (item.hasOfflineHistory && item.events && item.events.length > 0) {
                const parts = item.events[0].split('|');
                const ts = new Date(parseInt(parts[3]));
                text += "Since " + moment(ts).format('MMMM Do YYYY, h:mm:ss a') + ':';
            } else {
                text += "";
            }

            return text;
        },
        addTag() {
            if (this.tagToAdd && this.editor.item.tags.indexOf(this.tagToAdd) === -1) {
                this.editor.item.tags.push(this.tagToAdd);
                this.tagToAdd = '';
            }
        },
        removeTag(tag, item) {
            for (let i = 0; i < item.tags.length; i++) {
                if (item.tags[i] === tag) {
                    item.tags.splice(i, 1);
                    return;
                }
            }
        },
        editService(service) {
            console.log("EDIT: " + service.name);
            this.tagToAdd = '';
            this.alertsToDelete = [];
            this.editor = {
                item: this.$clone(service),
                alerts: this.$clone(this.serviceAlertsByServiceId(service.id)),
                show: true
            };
        },
        deleteService(service) {
            console.log("DELETE: " + service.name);
            this.deleteDialog = {
                show: true,
                item: service
            };
        },
        async confirmDeleteService() {
            const item = this.deleteDialog.item;
            if (!item) {
                console.error("Do delete service - no item!!");
                return;
            }

            const token = await this.$auth.getTokenSilently();
            const response = await fetch(process.env.VUE_APP_API_BASE + '/services/' + item.id, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.ok) {
                await this.$store.dispatch('services/fetch');
                await this.$store.dispatch('monitors/fetch');
                this.deleteDialog = {
                    show: false,
                    item: null
                };
            } else {
                this.$app.displaySnackbar('ERROR - could not delete ' + item.name, 4000, true);
            }
        },
        async saveService() {
            const isCreate = typeof this.editor.item.id !== 'string';
            const token = await this.$auth.getTokenSilently();

            let path = isCreate ? '/services' : '/services/' + this.editor.item.id;
            const response = await fetch(process.env.VUE_APP_API_BASE + path, {
                method: isCreate ? 'POST' : 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: this.editor.item.name.startsWith('TestXXX') ?
                    JSON.stringify({
                        successRate: 0.5,
                        type: "test",
                        name: this.editor.item.name,
                        url: this.editor.item.url,
                        interval: this.editor.item.interval,
                        headers: this.$clone(this.editor.item.headers),
                        tags: this.$clone(this.editor.item.tags),
                        active: this.editor.item.active,
                        checkCert: this.editor.item.checkCert,
                        userAgent: this.editor.item.userAgent
                    })
                    : JSON.stringify({
                        name: this.editor.item.name,
                        url: this.editor.item.url,
                        interval: this.editor.item.interval,
                        headers: this.$clone(this.editor.item.headers),
                        tags: this.$clone(this.editor.item.tags),
                        active: this.editor.item.active,
                        checkCert: this.editor.item.checkCert,
                        userAgent: this.editor.item.userAgent
                    })
            });
            if (response.ok) {
                for(let a = 0; a < this.editor.alerts.length; a++) {
                    const alert = this.editor.alerts[a];
                    if(alert.alertId) {
                        let alertPath = '/servicealerts/';
                        if (alert.id) {
                            alertPath += alert.id;
                            console.log("UPDATING alert " + alert);
                        } else {
                            console.log("CREATING alert " + alert);
                        }
                        await fetch(process.env.VUE_APP_API_BASE + alertPath, {
                            method: alert.id ? 'PUT' : 'POST',
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                alertId: alert.alertId,
                                serviceId: alert.serviceId,
                                downLimit: parseInt(alert.downLimit),
                                upLimit: parseInt(alert.upLimit)
                            })
                        });
                    } else {
                        console.log("Not handling alert with no alert set...");
                    }
                }

                for(const alert of this.alertsToDelete) {
                    await fetch(process.env.VUE_APP_API_BASE + '/servicealerts/' + alert.id, {
                        method: 'DELETE',
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });

                }
                this.alertsToDelete = [];

                await this.$store.dispatch('services/fetch');
                await this.$store.dispatch('servicealerts/fetch');
                await this.$store.dispatch('monitors/fetch');
                this.editor.show = false;
            }
        }
    },
}
</script>

<style>

</style>