<template>
    <v-container class="pa-6">
        Please wait while we log you in...
    </v-container>
</template>

<script>

export default {
    name: "Login",
    components: {},
    props: {},
    data: () => ({}),
    mounted() {
        this.$auth.loginWithRedirect();
    },
    computed: {
    },
    methods: {
    },
}
</script>
