const EventEmitter = require('events');

export default class extends EventEmitter {
    constructor(auth)
    {
        super();
        this.ws = null;
        this.token = null;
        this.auth = auth;
    }

    close() {
        if(this.ws) {
            this.stopKeepalive();
            this.ws.onclose = () => {};
            this.ws.close();
            this.ws = null;
        }
    }

    async connect() {

        if(!window.WebSocket) {
            return false;
        }

        const token = await this.auth.getTokenSilently();

        this.ws = new WebSocket(process.env.VUE_APP_API_WS);
        if(this.ws) {
            this.ws.onopen = () => {
                console.log("WebSocket ok");
                this.ws.send(JSON.stringify({ type: 'authenticate', token: token }));
                this.emit('connected');
                this.startKeepalive();
                if(this.hasBeenConnected) {
                    this.emit('reconnected');
                }
                this.hasBeenConnected = true;
            };
            this.ws.onclose = () => {
                console.log("WebSocket CLOSED");
                this.emit('closed');
                this.stopKeepalive();
                this.startPushReconnect();
                if(this.hasBeenConnected) {
                    this.emit('init-reconnect');
                }
            }
            this.ws.onerror = () => {
                this.emit('error');
                console.log("WebSocket ERROR");
            };
            this.ws.onmessage = (event) => {
                let jsonData = null;
                try {
                    jsonData = JSON.parse(event.data);
                } catch (e) {
                    console.error("Could not parse message: " + event.data);
                    return;
                }
                console.log("WebSocket MESSAGE: " + event.data);
                console.log(jsonData);
                switch(jsonData.type) {
                    case 'pong':
                        break;
                    case 'authenticate':
                        if(jsonData.success === true || jsonData.success === 'true') {
                            this.emit('auth-success');
                        } else {
                            this.emit('auth-fail');
                            this.startPushReconnect();
                        }
                        break;
                    default:
                        this.emit('message', jsonData);
                }
            };
        } else {
            console.error("Could not connect to push at " + process.env.VUE_APP_API_WS);
            //this.loadStatuses();
            this.emit('connection-failed');
            this.startPushReconnect();
        }
    }

    startPushReconnect() {
        console.log("Start reconnect timeout");
        if(this.ws) {
            const ws = this.ws;
            this.ws = null;
            ws.close();
        }
        if(this.reconnectTimeout !== null) {
            clearTimeout(this.reconnectTimeout);
        }
        this.reconnectTimeout = setTimeout(() => {
            this.reconnectTimeout = null;
            this.connect();
        }, 10000);
    }

    stopKeepalive() {
        if(this.keepaliveInterval !== null) {
            clearInterval(this.keepaliveInterval);
            this.keepaliveInterval = null;
        }
    }

    startKeepalive() {
        this.stopKeepalive();
        setInterval(() => {
            if(this.ws) {
                this.ws.send(JSON.stringify({ type: 'ping' }));
            }
        }, 30000);
    }

}