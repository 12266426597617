import Vue from 'vue';
const apiBaseUrl = process.env.VUE_APP_API_BASE;

// initial state
const state = {
    items: [],
    fetching: false
}

// getters
const getters = {
    computed: (state, getters, rootState, rootGetters) => {
        const alerts = rootGetters['alerts/idMap'];
        const items = JSON.parse(JSON.stringify(state.items));

        items.forEach(item => {
            if(Object.prototype.hasOwnProperty.call(alerts, item.alertId)) {
                item.alertName = alerts[item.alertId].name;
            } else {
                item.alertName = 'UNKNWON';
            }
        });
        return items;
    },
    byServiceId: (state, getters) => (id) => {
        return getters.computed.filter(i => i.serviceId === id);
    }
}

// actions
const actions = {
    async fetch({commit}) {
        if (state.fetching) {
            return;
        }

        commit('fetching');

        if(Vue.prototype.$auth.isAuthenticated) {
            const token = await Vue.prototype.$auth.getTokenSilently();

            const response = await fetch(apiBaseUrl + '/servicealerts', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            commit('fetchSuccess', (await response.json()).items);

            return response;
        } else {
            commit('fetchError', { message: 'Not authenticated' });
        }
    },
    updateLocal({commit}, data) {
        const {item} = data;
        commit('updateSuccess', item);
    }
}

// mutations
const mutations = {
    fetching(state) {
        state.fetching = true;
    },
    fetchError(state, response) {
        state.error = response.message;
        state.fetching = false;
        state.items = [];
    },
    fetchSuccess(state, items) {
        state.fetching = false;
        state.items = Array.isArray(items) ? items : [];
    },
    updateSuccess(state, item) {
        Array.update(state.items, item, 'id');
        state.processing = false;
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};