import Vue from 'vue';

const apiBaseUrl = process.env.VUE_APP_API_BASE;

// initial state
const state = {
    items: [],
    fetching: false
}

// getters
const getters = {
    sorted: (state) => {
        const items = JSON.parse(JSON.stringify(state.items));
        return items.sort((first, second) => ((!first.system || !second.system) || first.system === second.system) ?
            first.name.localeCompare(second.name) : first.system.localeCompare(second.system));
    },
    withState: (state, getters) => {
        const items = JSON.parse(JSON.stringify(getters.sorted));
        items.forEach(item => {
            item.stateCount = item.states.length;
            item.onlineCnt = 0;
            item.states.forEach(state => {
                if (state.state === 'ONLINE') {
                    item.onlineCnt++;
                }
            });
        });
        return items;
    },
    activeWithState: (state, getters) => {
        const items = JSON.parse(JSON.stringify(getters.withState));
        return items.filter(item => item.active && (item.stateCount > 0));
    },
    tags: (state) => {
        const tags = [];
        state.items.forEach(item => {
            item.tags.forEach(tag => {
                if (tags.indexOf(tag) === -1) {
                    tags.push(tag);
                }
            });
        });
        return tags;
    },
    online: (state, getters) => {
        const items = JSON.parse(JSON.stringify(getters.activeWithState));
        return items.filter(item => item.onlineCnt > 0);
    },
    offline: (state, getters) => {
        const items = JSON.parse(JSON.stringify(getters.activeWithState));
        return items.filter(item => item.onlineCnt === 0);
    },
    unstable: (state) => {
        const items = JSON.parse(JSON.stringify(state.items));
        return items.filter(item => (item.state === 'ONLINE' && item.hasOfflineHistory));
    },
    byId: (state) => (id) => {
        const item = state.items.find(i => i.id === id);
        if (item) {
            return JSON.parse(JSON.stringify(item));
        }
        return null;
    },
}

// actions
const actions = {
    async fetch({commit}) {

        if (state.fetching) {
            return;
        }

        commit('fetching');
        if (Vue.prototype.$auth.isAuthenticated) {
            const token = await Vue.prototype.$auth.getTokenSilently();

            const response = await fetch(apiBaseUrl + '/monitors', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            commit('fetchSuccess', (await response.json()).items);

            return response;
        } else {
            commit('fetchError', {message: 'Not authenticated'});
        }
    },
    updateLocal({commit}, data) {
        const {item} = data;
        commit('updateSuccess', item);
    }
}

// mutations
const mutations = {
    fetching(state) {
        state.fetching = true;
    },
    fetchError(state, response) {
        state.error = response.message;
        state.fetching = false;
        state.items = [];
    },
    fetchSuccess(state, items) {
        state.fetching = false;
        state.items = Array.isArray(items) ? items : [];
    },
    updateSuccess(state, item) {
        Array.update(state.items, item, 'id');
        state.processing = false;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};