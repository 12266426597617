<template>
    <v-container>
        <v-card elevation="0" class="pa-1">
            <v-card-text>
                <v-btn style="margin-right: 30px" @click="openCreate()">
                    <v-icon>mdi-plus</v-icon>
                    New statuspage
                </v-btn>
            </v-card-text>
        </v-card>
        <v-expansion-panels
            focusable
            class="py-1"
        >
            <v-expansion-panel
                v-for="(item) in statuspages"
                :key="item.name"
            >
                <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                        <v-row no-gutters>
                            <v-icon>mdi-earth</v-icon>
                            <span :style="'margin: auto 20px; font-weight: bold;' + (item.active ? '' : 'color: gray;')">
                                <a
                                    v-if="item.active"
                                    :href="'https://status.lookoutduty.com#' + item.name"
                                    target="_blank"
                                >
                                    https://status.lookoutduty.com#{{item.name}}
                                </a>
                                <strong v-else>
                                    https://status.lookoutduty.com#{{item.name}} (inactive)
                                </strong>
                            </span>
                           <span v-if="open" style="float: right;">
                           </span>
                        </v-row>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card>
                        <v-card-title>Configured services</v-card-title>
                        <v-card-text>
                        <v-chip
                            v-for="(serviceId) in item.services"
                            :key="serviceId"
                        ><strong>{{getServiceName(serviceId)}}</strong></v-chip>
                        </v-card-text>
                        <v-card-actions>
                    <v-btn class="mr-4" @click.stop="editStatusPage(item)">
                        <v-icon>mdi-pencil</v-icon>
                        Edit
                    </v-btn>
                    <v-btn class="mr-4" @click.stop="deleteStatusPage(item)" color="error">
                        <v-icon>mdi-delete</v-icon>
                        Delete
                    </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-dialog
            v-model="create.show"
            max-width="600"
        >
            <v-card class="pa-8">
                <v-card-title>New StatusPage</v-card-title>
                <v-card-text>
                    <v-text-field
                        v-if="!create.item.id"
                        v-model="create.item.name"
                        :rules="[ create.item.name.length > 0 && create.nameAvailable === create.item.name ]"
                        label="Page Name"></v-text-field>
                    <v-btn @click="checkNameAvailable()">Check availability</v-btn><br>
                    {{ nameValidationResult }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="(!create.item.id && (create.item.name.length === 0 || create.nameAvailable !== create.item.name))" @click="createStatusPage()">Create</v-btn>
                    <v-btn color="error" @click="closeCreate()">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="edit.show"
            max-width="600"
        >
            <v-card class="pa-8">
                <v-card-title>Edit {{ edit.item.name }}</v-card-title>
                <v-card-text>
                    <v-checkbox
                        v-model="edit.item.active"
                        label="Active"></v-checkbox>
                </v-card-text>
                <v-select
                    label="Included services"
                    :items="services"
                    item-text="name"
                    item-value="id"
                    :return-object="false"
                    v-model="edit.item.services"
                    multiple
                ></v-select>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="saveStatusPage()">Save</v-btn>
                    <v-btn color="error" @click="closeEditor()">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="deleteDialog.show"
            width="500"
        >
            <v-card>
                <v-card-title class="text-h5 lighten-2">
                    Delete {{ deleteDialog.item ? deleteDialog.item.name : '' }}
                </v-card-title>

                <v-card-text>
                    Deleting a status-page will delete all configuration data.<br>
                    This action cannot be undone!
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteDialog = { show: false, item: null }"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="error"
                        @click="confirmDeleteStatusPage()"
                    >
                        Delete Page
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>

import {mapGetters, mapState} from "vuex";

export default {
    name: "StatusPage",
    components: {},
    props: {},
    data: () => ({
        nameValidationResult: "",
        deleteDialog: {
            show: false,
            item: {}
        },
        create: {
            show: false,
            nameAvailable: null,
            item: {
                name: ""
            }
        },
        edit: {
            show: false,
            item: {}
        }
    }),
    mounted() {
        this.$store.dispatch('statuspages/fetch');
        this.$store.dispatch('services/fetch');
    },
    computed: {
        ...mapGetters({
            services: 'services/sorted'
        }),
        ...mapState({
            statuspages: state => state.statuspages.items
        })
    },
    methods: {
        getServiceName(serviceId) {
            const service = this.services.find(s => s.id === serviceId);
            if(service) {
                return service.name;
            }
            return "Unknown service";
        },
        editStatusPage(item) {
            this.edit = {
                show: true,
                item: JSON.parse(JSON.stringify(item))
            };
        },
        closeEditor() {
            this.edit = {
                show: false,
                item: {}
            };
        },
        openCreate() {
            this.nameValidationResult = "";
            this.create = {
                show: true,
                nameAvailable: null,
                item: {
                    id: null,
                    name: '',
                    services: [],
                    active: false
                }
            }
        },
        closeCreate() {
            this.create = {
                show: false,
                nameAvailable: null,
                item: {
                    id: null,
                    name: '',
                    services: [],
                    active: false
                }
            }
        },
        async checkNameAvailable() {
            const token = await this.$auth.getTokenSilently();
            const response = await fetch(process.env.VUE_APP_API_BASE + '/statuspage-name-available/' + this.create.item.name, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if(response.ok) {
                this.create.nameAvailable = this.create.item.name;
                this.nameValidationResult = this.create.item.name + " is available!"
            } else {
                this.create.nameAvailable = null;
                this.nameValidationResult = this.create.item.name + " is NOT available!"
            }
        },
        async createStatusPage() {
            const token = await this.$auth.getTokenSilently();
            const response = await fetch(process.env.VUE_APP_API_BASE + '/statuspages', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: this.create.item.name,
                    services: []
                })
            });
            if(response.ok) {
                this.$store.dispatch('statuspages/fetch');
                this.closeCreate();
            }
        },
        deleteStatusPage(item) {
            console.log("DELETE: " + item.name);
            this.deleteDialog = {
                show: true,
                item: item
            };
        },
        async confirmDeleteStatusPage() {
            const item = this.deleteDialog.item;
            if (!item) {
                console.error("Do delete service - no item!!");
                return;
            }

            const token = await this.$auth.getTokenSilently();
            const response = await fetch(process.env.VUE_APP_API_BASE + '/statuspages/' + item.id, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.ok) {
                this.$store.dispatch('statuspages/fetch');
                this.deleteDialog = {
                    show: false,
                    item: {}
                };
            } else {
                this.$app.displaySnackbar('ERROR - could not delete ' + item.name, 4000, true);
            }
        },
        async saveStatusPage() {
            const token = await this.$auth.getTokenSilently();
            const response = await fetch(process.env.VUE_APP_API_BASE + '/statuspages/' + this.edit.item.id, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: this.edit.item.name,
                    services: this.edit.item.services,
                    active: this.edit.item.active
                })
            });
            if(response.ok) {
                this.$store.dispatch('statuspages/fetch');
                this.closeEditor();
            }
        }
    },
}
</script>

<style scoped>

</style>