<template>
    <v-container>
        <v-card elevation="0" class="pa-1">
            <v-card-text>
                <v-btn style="margin-right: 30px" @click="openCreate()">
                    <v-icon>mdi-plus</v-icon>
                    Add alert
                </v-btn>
            </v-card-text>
        </v-card>
        <v-expansion-panels
            focusable
            class="py-1"
        >
            <v-expansion-panel
                v-for="(item) in alerts"
                :key="item.id"
            >
                <v-expansion-panel-header>
                    <template v-slot:default="{ open }">
                        <v-row no-gutters>
                            <span style="margin: auto; font-weight: bold;">
                                {{ item.name }}
                            </span>
                            <v-spacer style="flex:1;"></v-spacer>
                            <span v-if="open" style="float: right;">
                                <v-btn class="mr-4" @click.stop="editAlert(item)"><v-icon>mdi-pencil</v-icon>Edit</v-btn>
                                <v-btn class="mr-4" @click.stop="deleteAlert(item)" color="error"><v-icon>mdi-delete</v-icon>Delete</v-btn>
                            </span>
                        </v-row>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card class="mt-3" elevation="0">
                        <v-row>
                            <v-col>
                                <v-card-title>Alert</v-card-title>
                                <v-card-text>
                                    <span class="font-weight-bold">Name:</span> {{ item.name }}<br>
                                    <span class="font-weight-bold">Type:</span> {{ item.type }}<br>
                                </v-card-text>
                            </v-col>
                            <v-col>
                                <v-card-title>Configuration</v-card-title>
                                <v-card-text v-if="item.type==='email'">
                                    <span class="font-weight-bold">Recipients:</span><br>
                                    <v-chip
                                        v-for="(recipient) in item.params.emailRecipients"
                                        :key="recipient"
                                    >
                                        <strong>{{ recipient }}</strong>
                                    </v-chip>

                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>

        <v-dialog width="500px" v-model="createDialog.show">
            <v-card class="pa-8">
                <v-card-title>New Alert</v-card-title>
                <v-text-field v-model="createDialog.item.name" label="Name"></v-text-field>
                <v-select
                    label="Type"
                    :items="alertTypes"
                    item-text="name"
                    item-value="type"
                    v-model="createDialog.item.type"
                ></v-select>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="createAlert">Create</v-btn>
                    <v-btn color="error" @click="createDialog.show=false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog width="700px" v-model="editDialog.show">
            <v-card class="pa-8">
                <v-card-title>Edit Alert</v-card-title>
                <v-text-field v-model="editDialog.item.name" label="Name"></v-text-field>
                <v-container
                    v-if="editDialog.item.type === 'email'"
                >
                    <v-row>
                        <v-col>
                            Recipients: &nbsp;
                            <v-chip
                                v-for="(recipient) in editDialog.item.params.emailRecipients"
                                :key="recipient"
                                close
                                @click:close="removeRecipient(recipient, editDialog.item.params.emailRecipients)"
                            >
                                <strong>{{ recipient }}</strong>
                            </v-chip>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-text-field
                                    v-model="recipientToAdd"
                                    label="Add recipient"
                                    @keydown.enter="addRecipient()"
                                ></v-text-field>
                                <v-btn :disabled="!recipientToAdd || editDialog.item.params.emailRecipients.indexOf(recipientToAdd) >= 0"
                                       @click="addRecipient()">Add
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>


                </v-container>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="saveAlert">Save</v-btn>
                    <v-btn color="error" @click="editDialog.show=false">Cancel</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <v-dialog
            v-model="deleteDialog.show"
            width="500"
        >
            <v-card>
                <v-card-title class="text-h5 lighten-2">
                    Delete alert "{{ deleteDialog.item ? deleteDialog.item.name : '' }}"
                </v-card-title>

                <v-card-text>
                    This action cannot be undone!
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="deleteDialog = { show: false, item: null }"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="error"
                        @click="confirmDeleteAlert()"
                    >
                        Delete Alert
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import moment from 'moment';
import {mapState} from "vuex";

export default {
    name: "Alerts",
    components: {},
    props: {},
    data: () => ({
        recipientToAdd: "",
        createDialog: {
            show: false,
            item: {
                name: '',
                type: 'email'
            },
        },
        editDialog: {
            show: false,
            item: {
                name: '',
                type: '',
                params: {}
            },
        },
        deleteDialog: {
            show: false,
            item: null
        },
        alertTypes: [
            {
                name: 'E-mail',
                type: 'email'
            }/*,
            {
                name: 'Twitter',
                type: 'twitter'
            },
            {
                name: 'Slack',
                type: 'slack'
            },
            {
                name: 'WebHook',
                type: 'webhook'
            }*/
        ],
        rules: {
            url: str => {
                let url;

                try {
                    url = new URL(str);
                } catch (_) {
                    return false;
                }
                return url.protocol === "http:" || url.protocol === "https:";
            },
        }
    }),
    mounted() {
        this.$store.dispatch('alerts/fetch');
    },
    computed: {
        ...mapState({
            alerts: state => state.alerts.items
        })
    },
    methods: {
        addRecipient() {
            if (this.recipientToAdd && this.editDialog.item.params.emailRecipients.indexOf(this.recipientToAdd) === -1) {
                this.editDialog.item.params.emailRecipients.push(this.recipientToAdd);
                this.recipientToAdd = '';
            }
        },
        removeRecipient(email, list = []) {
            for (let i = 0; i < list.length; i++) {
                if (list[i] === email) {
                    list.splice(i, 1);
                    return;
                }
            }
        },
        openCreate() {
            this.createDialog = {
                show: true,
                item: {
                    name: '',
                    type: 'email'
                }
            };
        },
        capitalize(data) {
            if (typeof data !== 'string' || data.length === 0) {
                return '';
            }
            return data[0].toUpperCase() + data.substr(1);
        },
        getIcon(item) {
            switch (item.state) {
                case 'ONLINE':
                    return 'mdi-check-circle';
                case 'OFFLINE':
                    return 'mdi-alert';
            }
            return 'mdi-cloud-download';
        },
        getIconColor(item) {
            switch (item.state) {
                case 'OFFLINE':
                    return '#b71c1c';
                case 'UNKNOWN':
                    return '#777';
            }
            if (item.hasOfflineHistory === true) {
                return '#ccaa00';
            }
            return '#6f9a3b';
        },
        getStatusText(item) {
            if (!item) {
                return '';
            }
            let text = '';

            if (item.hasOfflineHistory && item.events && item.events.length > 0) {
                const parts = item.events[0].split('|');
                const ts = new Date(parseInt(parts[3]));
                text += "Since " + moment(ts).format('MMMM Do YYYY, h:mm:ss a') + ':';
            } else {
                text += "";
            }

            return text;
        },
        editAlert(item) {
            console.log("EDIT: " + item.name);
            this.recipientToAdd = "";
            const itemToEdit = this.$clone(item);
            if(!Array.isArray(itemToEdit.params.emailRecipients)) {
                itemToEdit.params.emailRecipients = [];
            }
            this.editDialog = {
                item: itemToEdit,
                show: true
            };
        },
        deleteAlert(item) {
            console.log("DELETE: " + item.name);
            this.deleteDialog.item = item;
            this.deleteDialog.show = true;
        },
        async confirmDeleteAlert() {
            const item = this.deleteDialog.item;
            if(!item) {
                console.error("Do delete service - no item!!");
                return;
            }

            const token = await this.$auth.getTokenSilently();
            const response = await fetch(process.env.VUE_APP_API_BASE + '/alerts/' + item.id, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if(response.ok) {
                await this.$store.dispatch('alerts/fetch');
                this.deleteDialog.item = null;
                this.deleteDialog.show = false;
            } else {
                this.$app.displaySnackbar('ERROR - could not delete ' + item.name, 4000, true);
            }
        },
        async createAlert() {
            const token = await this.$auth.getTokenSilently();

            const response = await fetch(process.env.VUE_APP_API_BASE + '/alerts', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: this.createDialog.item.name,
                    type: this.createDialog.item.type
                })
            });
            if(response.ok) {
                await this.$store.dispatch('alerts/fetch');
                this.createDialog.show = false;
            }
        },
        async saveAlert() {
            const token = await this.$auth.getTokenSilently();

            const response = await fetch(process.env.VUE_APP_API_BASE + '/alerts/' + this.editDialog.item.id, {
                method: 'PUT',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: this.editDialog.item.name,
                    type: this.editDialog.item.type,
                    params: this.$clone(this.editDialog.item.params)
                })
            });
            if(response.ok) {
                await this.$store.dispatch('alerts/fetch');
                this.editDialog.show = false;
            }
        }
    },
}
</script>

<style>

</style>