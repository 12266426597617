import Vue from 'vue';
const apiBaseUrl = process.env.VUE_APP_API_BASE;

// initial state
const state = {
    items: [],
    fetching: false
}

// getters
const getters = {
    sorted: (state) => {
        const items = JSON.parse(JSON.stringify(state.items));
        return items.sort((first, second) => first.name.localeCompare(second.name));
    },
    active: (state) => {
        const items = JSON.parse(JSON.stringify(state.items));
        return items.filter(item => item.active);
    },
    idMap: (state) => {
        return new Map(state.items.map(i => [i.key, i.val]));
    },
    byId: (state) => (id) => {
        const item = state.items.find(i => i.id === id);
        if(item) {
            return JSON.parse(JSON.stringify(item));
        }
        return null;
    }
}

// actions
const actions = {
    async fetch({commit}) {
        if (state.fetching) {
            return;
        }

        commit('fetching');

        if(Vue.prototype.$auth.isAuthenticated) {
            const token = await Vue.prototype.$auth.getTokenSilently();

            const response = await fetch(apiBaseUrl + '/alerts', {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            commit('fetchSuccess', (await response.json()).items);

            return response;
        } else {
            commit('fetchError', { message: 'Not authenticated' });
        }
    },
    updateLocal({commit}, data) {
        const {item} = data;
        commit('updateSuccess', item);
    }
}

// mutations
const mutations = {
    fetching(state) {
        state.fetching = true;
    },
    fetchError(state, response) {
        state.error = response.message;
        state.fetching = false;
        state.items = [];
    },
    fetchSuccess(state, items) {
        state.fetching = false;
        state.items = Array.isArray(items) ? items : [];
    },
    updateSuccess(state, item) {
        Array.update(state.items, item, 'id');
        state.processing = false;
    },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};