<template>
    <v-container fluid class="pa-8">
        <v-row>
            <v-col
                v-for="(item) in statusesFiltered"
                :key="item.id"
                md="4"
                lg="3"
                cols="12"
            >
                <v-card
                    @click.stop="openDetails(item)"
                    :color="getStatusColor(item)"
                    style="transition: background-color 1.5s ease; color: #333333"
                >
                    <v-card-title>
                        <v-icon
                            v-if="item.active && item.onlineCnt === 0"
                            color="#b71c1c"
                            style="padding-right: 20px;"
                        >
                            mdi-alert
                        </v-icon>
                        {{ item.name }}
                        <span
                            style="padding-left: 20px;"
                        >
                            <v-icon
                                v-for="alert in offlineAlerts(item)"
                                :key="alert.id"
                                color="#b71c1c"
                            >mdi-alert-decagram</v-icon>
                        </span>
                        <v-spacer></v-spacer>

                        <v-icon
                            v-if="hasCertificateData(item)"
                            :color="hasExpiringData(item, 30) ? 'red' : 'green'"
                        >mdi-certificate
                        </v-icon>
                    </v-card-title>
                    <v-card-subtitle style="color: #333333">
                        {{ getTagsString(item) }} &nbsp;
                        <v-progress-linear
                            :value="Math.ceil(100 * (item.onlineCnt / item.stateCount))"
                            :color="getStatusBarColor((item.onlineCnt / item.stateCount))"
                            height="20"
                        >
                            <template v-slot:default="{ }">
                                <strong>{{ item.onlineCnt + '/' + item.stateCount }}</strong>
                            </template>
                        </v-progress-linear>
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-model="detailsView.show"
            scrollable
            :fullscreen="!$vuetify.breakpoint.mdAndUp"
            :width="$vuetify.breakpoint.mdAndUp ? '90%' : ''"
        >
            <MonitorDetails
                :itemId="detailsView.itemId"
                @close="closeDetails"
            ></MonitorDetails>
        </v-dialog>
    </v-container>
</template>

<script>
import moment from 'moment';
import {mapGetters} from "vuex";
import MonitorDetails from "../components/MonitorDetails";

export default {
    name: "MonitorLiveView",
    components: {MonitorDetails},
    data: () => ({
        currStatusItem: null,
        currStatusData: null,
        currentHistoryConvertedAll: [{name: '', data: []}],
        currentHistoryConverted: [{name: '', data: []}],
        currentItemEvents: [],
        currTotalAvg: 0,
        detailsView: {show: false, itemId: null},
        currHoverData: null,
        countryColors: {},
        currIsoFilter: null,
    }),
    computed: {
        ...mapGetters({
            statuses: 'monitors/activeWithState',
        }),
        detailsHeader() {
            if (this.currHoverData) {
                return "Request latency - " + this.currHoverData.name + ", avg response " + this.currHoverData.avg.toFixed(3) + "s";
            }
            return "Request latency - avg response " + this.currTotalAvg.toFixed(3);
        },
        statusesFiltered() {
            if (this.$app.tagFilter.length === 0) {
                return this.statuses;
            }
            return this.statuses.filter(item => {
                for (let i = 0; i < item.tags.length; i++) {
                    if (this.$app.tagFilter.indexOf(item.tags[i]) >= 0) {
                        return true;
                    }
                }
                return false;
            });
        }
    },
    methods: {
        offlineAlerts(item) {
            return item.alerts.filter(a => a.state === 'OFFLINE');
        },
        openDetails(item) {
            this.detailsView = {
                itemId: item.id,
                show: true
            };
        },
        closeDetails() {
            this.detailsView = {
                itemId: null,
                show: false
            };
        },
        hasCertificateData(item) {
            if (item) {
                return item.states.find(s => s.certData && s.certData.validUntilTs !== null) !== undefined;
            }
            return false;
        },
        hasExpiringData(item, daysLeft) {
            if (item) {
                return item.states.find(s => s.certData && s.certData.daysLeft && s.certData.daysLeft < daysLeft) !== undefined;
            }
            return false;
        },
        getStatusBarColor(value) {
            if (value < 0.25) {
                return 'rgb(255, 50, 50)'
            }
            if (value < 0.50) {
                return 'rgb(222,191,92)'
            }
            return 'rgb(35,185,38)';
        },
        capitalize(data) {
            if (typeof data !== 'string' || data.length === 0) {
                return '';
            }
            return data[0].toUpperCase() + data.substr(1);
        },
        getStatusColor(item) {
            if (!item.active || item.stateCount === 0) {
                return '#bbb';
            }

            if (item.onlineCnt > 0) {
                if (item.onlineCnt < item.stateCount) {
                    return '#fded9b';
                }
                return '#b0d780';
            }
            return '#ff6d6d';
            /*
                        switch (item.state) {
                            case 'OFFLINE':
                                return '#ff6d6d';
                            case 'UNKNOWN':
                                return '#adadad';
                        }
                        if (item.hasOfflineHistory === true) {
                            return '#fded9b';
                        }
                        return '#b0d780';
             */
        },
        getTagsString(item) {
            let retval = "";
            try {
                item.tags.forEach((tag, idx) => {
                    retval += (idx > 0) ? ', ' + tag : tag;
                });
            } catch (e) {
                console.error("Error accessing tags: " + e);
                console.error(item);
            }
            return retval;
        },
        getStatusText(item) {
            if (!item) {
                return '';
            }
            let text = '';

            if (item.hasOfflineHistory && item.events && item.events.length > 0) {
                const parts = item.events[0].split('|');
                const ts = new Date(parseInt(parts[3]));
                text += "Since " + moment(ts).format('MMMM Do YYYY, h:mm:ss a') + ':';
            } else {
                text += "";
            }

            return text;
        }
    }
}
</script>

<style scoped>

</style>